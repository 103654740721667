body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}


#app {
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: #282c34;
  height: 100vh;
  width: 100%;
  position: absolute;
  background-image: url('./img/main-background.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  scroll-behavior: smooth
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
